@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "charter";
  src: local("charter"),
    url(./fonts/charter_regular-webfont.woff) format("woff");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* textarea {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
} */

/* .sidebar {
  box-shadow: 3px 0 3px 0 #000;
} */

.github-corner:hover .octo-arm {
  animation: octocat-wave 560ms ease-in-out;
}

@keyframes octocat-wave {
  0%,
  100% {
    transform: rotate(0);
  }

  20%,
  60% {
    transform: rotate(-25deg);
  }

  40%,
  80% {
    transform: rotate(10deg);
  }
}

@media (max-width: 500px) {
  .github-corner:hover .octo-arm {
    animation: none;
  }

  .github-corner .octo-arm {
    animation: octocat-wave 560ms ease-in-out;
  }
}

.bg-dark {
  background-color: rgb(24, 26, 27);
}

.bg-dark-2 {
  background-color: #212529;
}

.bg-dark-2:hover {
  background-color: #212529;
}

/* .neu-brutalism-shadow {
  box-shadow: 0.4rem 0.4rem 0 #222;
} */

.dot {
  display: inline-block;
  background-color: #000;
  border-radius: 50%;
  width: 0.5rem;
  height: 0.5rem;
  animation: fade 1s infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.5s;
}

.dot:nth-child(3) {
  animation-delay: 1s;
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

a {
  text-decoration: none;
}

span {
  line-height: 9px;
  vertical-align: 50%;
}

.window {
  /* background: #fff; */
  /* width: 50vw; */
  /* height: 75vh; */
  /* margin: auto; */
  /* margin-top: 12.5vh; */
  /* border: 1px solid #acacac; */
  /* border-radius: 6px; */
  /* box-shadow: 0px 0px 20px #acacac; */
}

.titlebar {
  /* background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #ebebeb, color-stop(1, #d5d5d5))
  );
  background: -webkit-linear-gradient(top, #ebebeb, #d5d5d5);
  background: -moz-linear-gradient(top, #ebebeb, #d5d5d5);
  background: -ms-linear-gradient(top, #ebebeb, #d5d5d5);
  background: -o-linear-gradient(top, #ebebeb, #d5d5d5);
  background: linear-gradient(top, #ebebeb, #d5d5d5); */
  color: #000;
  font-size: 11pt;
  line-height: 20px;
  text-align: center;
  width: 100%;
  height: 20px;
  /* border-top: 1px solid #f3f1f3;
  border-bottom: 1px solid #b1aeb1; */
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  cursor: default;
}

.buttons {
  padding-left: 8px;
  padding-top: 3px;
  float: left;
  line-height: 0px;
}

.buttons:hover a {
  visibility: visible;
}

.close {
  background: #ff5c5c;
  font-size: 9pt;
  /* width: 15px;
  height: 15px; */
  border: 1px solid #e33e41;
  border-radius: 50%;
  display: inline-block;
}

.close:active {
  background: #c14645;
  border: 1px solid #b03537;
}

.close:active .closebutton {
  color: #4e0002;
}

.closebutton {
  color: #820005;
  visibility: hidden;
  cursor: default;
}

.minimize {
  background: #ffbd4c;
  font-size: 9pt;
  /* line-height: 15px; */
  margin-left: 4px;
  /* width: 15px;
  height: 15px; */
  border: 1px solid #e09e3e;
  border-radius: 50%;
  display: inline-block;
}

.minimize:active {
  background: #c08e38;
  border: 1px solid #af7c33;
}

.minimize:active .minimizebutton {
  color: #5a2607;
}

.minimizebutton {
  color: #9a5518;
  visibility: hidden;
  cursor: default;
}

.zoom {
  background: #00ca56;
  font-size: 9pt;
  /* line-height: 15px; */
  margin-left: 6px;
  /* width: 15px;
  height: 15px; */
  border: 1px solid #14ae46;
  border-radius: 50%;
  display: inline-block;
}

.zoom:active {
  background: #029740;
  border: 1px solid #128435;
}

.zoom:active .zoombutton {
  color: #003107;
}

.zoombutton {
  color: #006519;
  visibility: hidden;
  cursor: default;
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 5px;
  background-color: #000;
  color: #000;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1s infinite linear;
  animation-delay: 0.25s;
  margin-left: 1rem;
  margin-top: 0.5rem;
}
.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 5px;
  background-color: #000;
  color: #000;
}
.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1s infinite linear;
  animation-delay: 0s;
}
.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px;
  }
}
